










































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
import Spinners from "@/components/utilities/Spinners.vue";
import {
    AttendeeSearchFormFields,
    AttendeeSearchInput
} from "@/types/interfaces";

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

interface SearchFieldOutput {
    name: string;
    isDropdown: boolean;
    items: Array<string>;
}

export default Vue.extend({
    data() {
        return {
            isLoading: false,
            formFields: {
                firstName: "",
                lastName: "",
                companyName: "",
                companyType: "",
                country: "",
                city: "",
                state: "",
                title: "",
                specialInterests: "",
                secondaryInterests: "",
                system: "",
                graduationYear: "",
                school: "",
                attendeeBanner: ""
            } as AttendeeSearchFormFields
        };
    },
    components: {
        Spinners
    },
    props: {
        profileOwnerId: {
            type: String,
            default: ""
        }
    },

    mounted() {
        attendeeSearchStore.getDropdownFields();
    },
    computed: {
        pageOptions(): Record<string, any> {
            return store.getters.getPageOptions("attendees");
        },

        searchFields(): Array<string> {
            return Array.isArray(this.pageOptions.searchFields)
                ? this.pageOptions.searchFields
                : ["firstName", "lastName", "companyName", "country"];
        },

        searchDropdownFields(): Array<string> {
            return Array.isArray(this.pageOptions.searchDropdownFields)
                ? this.pageOptions.searchDropdownFields
                : [];
        },

        validSearchFields(): Record<string, any> {
            return this.searchFields
                .map((field: string) => {
                    const isDropdown = this.searchDropdownFields.includes(
                        field
                    );
                    const dropdown = this.getDropdownField(field);
                    const dropdownItems = dropdown ? dropdown.items : [];

                    return {
                        name: field,
                        isDropdown: isDropdown,
                        items: dropdownItems
                    };
                })
                .filter((field: SearchFieldOutput) => {
                    return (
                        !field.isDropdown ||
                        (field.isDropdown && field.items.length)
                    );
                });
        },

        formLabels(): string {
            return this.$store.getters.getAttendeeLabels;
        },

        dropdownFields() {
            return attendeeSearchStore.dropdownFields;
        },

        hasAnySearchFields(): boolean {
            let hasFields = false;

            Object.keys(this.formFields).forEach((keyName) => {
                const key = keyName as keyof AttendeeSearchFormFields;
                if (this.formFields[key] != "") {
                    hasFields = true;
                }
            });

            return hasFields;
        },

        fieldClass(): string {
            return this.pageOptions.searchFieldClass
                ? this.pageOptions.searchFieldClass
                : "flex-item--half";
        }
    },
    methods: {
        getDropdownField(fieldName: string) {
            const potentialField = this.dropdownFields.find(
                (field: any) => field.name === fieldName
            );

            return potentialField ? potentialField : false;
        },

        submitForm() {
            if (!this.hasAnySearchFields) {
                return;
            }

            this.isLoading = true;

            const searchInput = {
                matchFirstName: this.formFields.firstName,
                matchLastName: this.formFields.lastName,
                matchCompanyName: this.formFields.companyName,
                matchCompanyType: this.formFields.companyType,
                matchCountry: this.formFields.country,
                matchCity: this.formFields.city,
                matchState: this.formFields.state,
                matchTitle: this.formFields.title,
                matchSpecialInterest: this.formFields.specialInterests,
                matchSecondaryInterest: this.formFields.secondaryInterests,
                matchSystem: this.formFields.system,
                matchSchool: this.formFields.school,
                matchGraduationYear: this.formFields.graduationYear,
                matchAttendeeBanner: this.formFields.attendeeBanner
            };

            const query = { ...searchInput } as AttendeeSearchInput;

            Object.keys(query).forEach((key) => {
                const itemKey = key as keyof AttendeeSearchInput;
                if (!query[itemKey] || query[itemKey] == "") {
                    delete query[itemKey];
                }
            });

            this.$router.push({
                path: "/attendees/results",
                query: { ...query }
            });
        },

        clearForm() {
            Object.keys(this.formFields).forEach((keyName) => {
                const key = keyName as keyof AttendeeSearchFormFields;
                this.formFields[key] = "";
            });
        }
    }
});
